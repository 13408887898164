import React from 'react';
import { List } from 'antd';
import { marketService } from 'services/market';

const UserItems = ({ params, onUpdateCounter }) => {
  const [dataList, setDataList] = React.useState([]);
  const [dataLoading, setDataLoading] = React.useState(false);

  const fetchData = React.useCallback(() => {
    setDataLoading(true);
    marketService
      .searchItem({ ...params, limit: 30 })
      .then(({ data }) => {
        setDataList(data.nfts);
        onUpdateCounter(data.total);
      })
      .finally(() => {
        setDataLoading(false);
      });
  }, [params, onUpdateCounter]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
  <div>dsa</div>
  );
};

export default UserItems;
