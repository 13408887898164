import image_artist_1 from './artist/image-artist-1.jpg';
import image_artist_2 from './artist/image-artist-2.jpg';
import image_artist_3 from './artist/image-artist-3.jpg';
import image_artist_4 from './artist/image-artist-4.jpg';
import image_artist_5 from './artist/image-artist-5.jpg';
import image_artist_6 from './artist/image-artist-6.jpg';
import plane from './plane.png';
import moto from './moto.png';
import roadster from './roadster.png';
import bullet from './bullet.png';
import car from './car.png';
import bg_home from '../images/bg-home.png';
import iconRight from './arrow-right.png';
import bg_countdown from './bg-countdown.png';
import virtual_item from './virtual-item.png';
import ic_dollar from './ic_dollar.png';
import ic_dollar_new from './ic_dollar_new.png';
import ic_plain from './ic_plain.png';
import bg_welcome from './bg-welcome.png';
import ic_cart from './ic_cart.png';
import ic_cart1 from './ic_cart1.png';
import ic_back from './ic_back.png';
import ic_addcart from './add_cart.png';
import ic_add_to_cart from './add-to-cart.png';
import ic_warning from './warning.png';
import btn_addcart from './btn-addcart.png';
import btn_buy from './btn-buy.png';
import btn_buyInactive from './btn-buyInactive.png';
import s_1 from './s-1.png';
import s_2 from './s-2.png';
import s_3 from './s-3.png';
import s_4 from './s-4.png';
import s_5 from './s-5.png';
import s_6 from './s-6.png';
import s_7 from './s-7.png';
import s_8 from './s-8.png';


const artists = [image_artist_1, image_artist_2, image_artist_3, image_artist_4, image_artist_5, image_artist_6];

const images = {
  bg_home: bg_home,
  iconRight: iconRight,
  bg_countdown: bg_countdown,
  virtual_item: virtual_item,
  ic_dollar: ic_dollar,
  ic_dollar_new: ic_dollar_new,
  ic_plain: ic_plain,
  bg_welcome: bg_welcome,
  ic_cart: ic_cart,
  ic_cart1: ic_cart1,
  ic_back: ic_back,
  ic_addcart: ic_addcart,
  btn_addcart: btn_addcart,
  btn_buy: btn_buy,
  btn_buyInactive:btn_buyInactive,
  ic_warning: ic_warning,
  car:car,
  bullet,
  plane,
  roadster,
  moto,
  s_1,
  s_2,
  s_3,
  s_4,
  s_5,
  s_6,
  s_7,
  s_8
};

export { images, artists };
/*
export const images = {
  banner_introduce: './banner-introduce.png',
  rarity: './rarity.png',
  rarity1: './rarity.png',
  rarity2: './rarity.png',
  rarity3: './rarity.png',
  rarity4: './rarity.png',
  lootbox: './lootbox.png',
  line: './line.png',
  texttime: './texttime.png',
  item_drop1: './item-drop1.png',
  item_drop: './item-drop.png',
  ic_ratiry: './ic-rarity.png',
  thumb_art: './art_thumb.png',
};
};

export { images, artists };
*/
