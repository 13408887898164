import React from 'react';
import { List } from 'antd';
import { marketService } from 'services/market';
import { CardCollection } from 'views/Collection/components';

const UserCollections = ({ onUpdateCounter }) => {
  const [dataList, setDataList] = React.useState([]);
  const [dataLoading, setDataLoading] = React.useState(false);

  const fetchData = React.useCallback(() => {
    setDataLoading(true);
    marketService
      .fetchSubCollections()
      .then(({ data }) => {
        setDataList(data.subCollections);
        onUpdateCounter(data.subCollections.length);
      })
      .finally(() => {
        setDataLoading(false);
      });
  }, [onUpdateCounter]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <List
      grid={{ gutter: [24, 8], column: 5, xs: 1, sm: 1, md: 2, lg: 3 }}
      dataSource={dataList}
      loading={dataLoading}
      renderItem={(item) => (
        <List.Item>
          <CardCollection item={item} />
        </List.Item>
      )}
    />
  );
};

export default UserCollections;
