import { store } from 'reducers';
import { ActionType } from 'reducers/profile';
import { notification } from 'antd';
import { userService } from 'services/user';
import Web3 from 'web3';

const requireMetaMask = () => {
  notification.error({
    message: 'Cannot connect to MetaMask',
    description: (
      <a
        href='https://metamask.io/download.html'
        className='MuiTypography-colorSecondary'
        target='_blank'
        rel='noreferrer'
      >
        Install MetaMask
      </a>
    ),
    duration: 10,
  });
};

const connect = async (callback) => {
  if (window.ethereum) {
    window.web3 = new Web3(window.ethereum);
  } else if (window.web3) {
    window.web3 = new Web3(window.web3.currentProvider);
  } else {
    return requireMetaMask();
  }

  const [user] = await window.ethereum.request({ method: 'eth_requestAccounts' });

  const { isLoggedIn } = store.getState().profile;
  if (isLoggedIn) {
    if (typeof callback === 'function') callback();
  } else {
    try {
      const { data: sign } = await userService.nonce({ address: user });
      const message = `Welcome to Spores Marketplace!\n\nClick "Sign" to sign in. No password needed!\n\nI accept the Spores Marketplace TOS: https://spores.app/terms-of-use\n\nWallet address:\n${sign.address}\n\nNonce:\n${sign.nonce}`;

      const signature = await window.web3.eth.personal.sign(message, sign.address);
      const { data: login } = await userService.login({ address: sign.address, signature, nonce: sign.nonce });

      signIn({ id: sign.address, ...login });
      fetchProfile(sign.address);
      if (typeof callback === 'function') callback();
    } catch (error) {
      if (error.code === 4001) {
        notification.error({ message: 'Please connect to MetaMask' });
      }
    }
  }
};

const signIn = (profile) => {
  store.dispatch({
    type: ActionType.USER_LOGIN,
    data: profile,
  });
};

const fetchProfile = async () => {
  const { data: profile } = await userService.getProfile();
  store.dispatch({
    type: ActionType.USER_FETCH_PROFILE,
    data: profile,
  });
};

const signOut = () => {
  store.dispatch({
    type: ActionType.USER_LOGOUT,
  });
};

export const profileAction = {
  connect,
  fetchProfile,
  signIn,
  signOut,
  requireMetaMask,
};
