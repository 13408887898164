import { useState, useEffect } from 'react';
import './style.scss'
import { ShoppingCartOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { images } from '../../assets/images/index';
import Checkout from './Checkout';
import { checkoutService } from 'services/lootbox/checkout';
import { landContract } from "contracts/land/index";
import { netrvkContract, usdcContract } from "contracts/land/index";
import Web3 from 'web3';
import moment from 'moment';
import { URL_LAND } from 'env.js'
import { USDT_CONTRACT, NETVRK_CONTRACT, USDC_CONTRACT, ADDRESS_RECEIVER, GAS } from 'env'
import { notification, Select } from 'antd';
import { cartAction } from 'actions/cart';
import { Box, Button, Divider, Hidden, IconButton, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { outputCurrency } from 'env.js';
import { useHistory, useLocation } from 'react-router-dom';



const { Option } = Select;

function Cart() {
    const { t, i18n } = useTranslation('common');
    const [listCart, setListCart] = useState(null);
    const [landItem, setLandItem] = useState([])
    const [avatarItem, setAvartarItem] = useState([])
    const [isShowCheckout, setIsShowCheckout] = useState(false);
    const [transactionhash, setTransactionHash] = useState("");
    const [order, setOrder] = useState(null);
    const [typeSubmit, setTypeSubmit] = useState("land");
    const [orderDetail, setOrderDetail] = useState([])
    const [typeCoin, setTypeCoin] = useState("USDT")
    const [disableLand, setDisableLand] = useState(false);
    const [disableAvatar, setDisableAvatar] = useState(false);
    const [exceedModal, setExceedModal] = useState(false);
    const [openExchangeModal, setOpenExchangeModal] = useState(false)
    const [userLevel, setUserLevel] = useState({})
    const [validate, setValidate] = useState([])
    const history = useHistory();

    useEffect(() => {
        const listCardLand = localStorage.getItem("card_checkout_land");
        const listAvartarLand = localStorage.getItem("card_checkout_avatar");
        let cardLand = [];
        let cardAvatar = []
        if (listCardLand != null && listCardLand != undefined) {
            cardLand = JSON.parse(listCardLand);
            const json = [];
            cardLand.map(m => {
                const amountTem = m.amount?m.amount:1;
                json.push({ ...m, amount: amountTem, totalPrice: amountTem *parseInt(m.product.price) })
            })
            setLandItem(json)
        }
        if (listAvartarLand != null && listAvartarLand != undefined) {
            cardAvatar = JSON.parse(listAvartarLand)
            const json = [];
            cardAvatar.map(m => {
                json.push({ ...m, amount: m.amount?m.amount:1, totalPrice: m.product.price })
            })
            setAvartarItem(json);
        }
        const arr = [
            {
                title: 'VIRTUAL LAND',
                data: cardLand
            }
        ]
        setListCart(arr)
    }, [])
    useEffect(async () => {
        const res = await checkoutService.getUserLever();
        if (res) {
            setUserLevel(res)
        }
    }, [])
    const handleSetShowModal = () => {
        setIsShowCheckout(false)
    }

    const handleCHeck=async()=>{
        let check = true;
        if(landItem && landItem.length>0){
            const useLevel = await checkoutService.getUserLever();
        if (useLevel.data == 'unlimited') {
            setValidate([])
            return true;
        }
        if (useLevel) {
            const listCardLand = localStorage.getItem("card_checkout_land");
            const resValidate = [];
            if (listCardLand != null && listCardLand != undefined) {
                JSON.parse(listCardLand).forEach(products => {
                    const checkoutFilter = landItem.filter(f => f.product.id == products.product.id)[0];
                    const max = useLevel.data[products.product.name];
                    const amountNum = parseInt(checkoutFilter["amount"]);
                    if (products.product.product_type == 1) {
                        if (max == undefined || max == 0 || amountNum >max ) {
                            setDisableLand(true);
                            setExceedModal(true);
                            check = false;
                            resValidate.push(products.product.name)
                        }
                    }
                });
            }
            setValidate(resValidate);
        }
        }
        if(check){
            setValidate([])
        }
        return check;

    }
    
    const handleRemoveAll = () => {
        localStorage.removeItem("card_checkout_land");
        localStorage.removeItem("card_checkout_avatar");
        setAvartarItem([]);
        setLandItem([])
        const arr = [
            {
                title: 'VIRTUAL LAND',
                data: []
            },
            // {
            //     title: 'AVATARS & BONUS PACK',
            //     data: []
            // },
        ]
        setListCart(arr);
        cartAction.updateOrderCount();
    }

    function handleChange(value) {
        setTypeCoin(value)
    }

    const handleRemoveItem = (type, id) => {
        if (type == "VIRTUAL LAND") {
            const res = landItem.filter(f => f.product.id != id);
            setLandItem(res)
        } else if (type == "AVATARS & BONUS PACK") {
            const res = avatarItem.filter(f => f.product.id != id);
            setAvartarItem(res)
        }
        let land = [];
        let avatar = [];

        listCart.map(m => {
            if (m.title == "VIRTUAL LAND") {
                land = m.data.filter(f => f.product.id != id)
            } else if (m.title == "AVATARS & BONUS PACK") {
                avatar = m.data.filter(f => f.product.id != id)
            }
        })
        const arr = [
            {
                title: 'VIRTUAL LAND',
                data: land
            }
        ]
        localStorage.setItem("card_checkout_land", JSON.stringify(land));
        localStorage.setItem("card_checkout_avatar", JSON.stringify(avatar));
        setListCart(arr);
        cartAction.updateOrderCount();
    }

    useEffect(()=>{
        if(landItem && landItem.length > 0){
            localStorage.setItem("card_checkout_land",JSON.stringify(landItem))
        }
    },[landItem])


    const handlePlus = (id, type) => {
        if (type == "VIRTUAL LAND") {
            const res = landItem.filter(f => f.product.id == id)[0];
            res["amount"] = res["amount"] + 1;
            res["totalPrice"] = res["amount"] * res["product"]["price"];
            const resNot = landItem.filter(f => f.product.id != id);
            resNot.push(res)
            setLandItem(resNot)
        } else if (type == "AVATARS & BONUS PACK") {
            const res = avatarItem.filter(f => f.product.id == id)[0];
            res["amount"] = res["amount"] + 1;
            res["totalPrice"] = res["amount"] * res["product"]["price"];
            const resNot = avatarItem.filter(f => f.product.id != id);
            resNot.push(res)
            setAvartarItem(resNot)
        }
    }
    const handleMinus = (id, type) => {
        if (type == "VIRTUAL LAND") {
            const res = landItem.filter(f => f.product.id == id)[0];
            if (res["amount"] > 1) {
                res["amount"] = res["amount"] - 1;
                res["totalPrice"] = res["amount"] * res["product"]["price"];
                const resNot = landItem.filter(f => f.product.id != id);
                resNot.push(res)
                setLandItem(resNot)
            }
        } else if (type == "AVATARS & BONUS PACK") {
            const res = avatarItem.filter(f => f.product.id == id)[0];
            if (res["amount"] > 1) {
                res["amount"] = res["amount"] - 1;
                res["totalPrice"] = res["amount"] * res["product"]["price"];
                const resNot = avatarItem.filter(f => f.product.id != id);
                resNot.push(res)
                setAvartarItem(resNot)
            }
        }
    }
    const getTotal = (type, field) => {
        let total = 0
        if (type == "VIRTUAL LAND") {
            landItem.map(m => {
                total = total + m[field]
            })

        } else if (type == "AVATARS & BONUS PACK") {
            avatarItem.map(m => {
                total = total + m[field]
            })
        }
        return total;
    }
    const handleGetData = (id, type, field) => {
        if (type == "VIRTUAL LAND" && landItem.length > 0) {
            const res = landItem.filter(f => f.product.id == id)[0];
            if (res) {
                return res[field]
            }
        } else if (type == "AVATARS & BONUS PACK" && avatarItem.length > 0) {
            const res = avatarItem.filter(f => f.product.id == id)[0];
            if (res) {
                return res[field]
            }
        }
        return ""
    }

    const handlRefresh = () => {
        const listCardLand = localStorage.getItem("card_checkout_land");
        const listAvartarLand = localStorage.getItem("card_checkout_avatar");
        let cardLand = [];
        let cardAvatar = []
        if (listCardLand != null && listCardLand != undefined) {
            cardLand = JSON.parse(listCardLand);
            const json = [];
            cardLand.map(m => {
                json.push({ ...m, amount: 1, totalPrice: m.product.price })
            })
            setLandItem(json)
        } else {
            setLandItem([])
        }
        if (listAvartarLand != null && listAvartarLand != undefined) {
            cardAvatar = JSON.parse(listAvartarLand)
            const json = [];
            cardAvatar.map(m => {
                json.push({ ...m, amount: 1, totalPrice: m.product.price })
            })
            setAvartarItem(json);
        } else {
            setAvartarItem([])
        }
        const arr = [
            {
                title: 'VIRTUAL LAND',
                data: cardLand
            }
        ]
        setListCart(arr)
    }

    const handlePrice = (price, number) => {
        let temp = price;
        for (let i = 0; i < number; i++) {
            temp = temp * 10;
            if (isInt(temp)) {
                let v = number - (i + 1);
                for (let j = 0; j < v; j++) {
                    temp = temp + "0"
                }
                return temp;
            }
        }
    }

    function isInt(n) {
        return Number(n) === n && n % 1 === 0;
    }

    function isFloat(n) {
        return Number(n) === n && n % 1 !== 0;
    }

    const handleCheckout = async (type) => {
        const check =await handleCHeck();
        if(!check){
            notification.error({message:"Quota exceeded. Please check your order again."})
            return;
        }
        if (type == "VIRTUAL LAND") {
            handleCheckoutLand()
        } else if (type == "AVATARS & BONUS PACK") {
            handleCheckoutAvartar()
        }
    }
    // const aA = async () => {
    //     setTransactionHash("")
    //     const avatar = [];
    //     const pack = [];
    //     avatarItem.map(m => {
    //         if (m.product.product_type == 2) {
    //             const item = {
    //                 "product_id": m.product.id,
    //                 "amount": m.amount
    //             }
    //             avatar.push(item);
    //         }
    //         if (m.product.product_type == 3) {
    //             const item = {
    //                 "product_id": m.product.id,
    //                 "amount": m.amount
    //             }
    //             pack.push(item);
    //         }

    //     })
    //     const body = {
    //         "currency":"NTVRK",
    //         "avatars": avatar,
    //         "packs": pack
    //     }
    //     const res = await checkoutService.avartarCheckout(body)
    //     setOrder(res.data.order)
    //     //  handl checkout
    //     // if (window.ethereum) {
    //     //     window.web3 = new Web3(window.ethereum);
    //     //   } else if (window.web3) {
    //     //     window.web3 = new Web3(window.web3.currentProvider);
    //     //   }
    //     // const [user] = await window.ethereum.request({ method: 'eth_requestAccounts' });
    //     setIsShowCheckout(true)
    //     // const landRes = await netrvkContract(NETVRK_CONTRACT).methods.transfer(ADDRESS_RECEIVER, handlePrice(1)).send({ from: user });
    //     setTypeSubmit("avatar")
    //     // setTransactionHash(landRes.transactionHash);

    //     setOpenExchangeModal(true)
    // }
    const handleCheckoutLand = async () => {
        setTransactionHash("")
        cartAction.setTranshash("")
        const product = [];
        landItem.map(m => {
            const item = {
                "product_id": m.product.id,
                "amount": m.amount
            }
            product.push(item);
        })
        if (product && product.length == 0) {
            return;
        }
        const body = {
            "currency": typeCoin,
            "products": product
        }
        const res = await checkoutService.landCheckout(body)
        setOrder(res.data.order)
        //  handl checkout
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum);
        } else if (window.web3) {
            window.web3 = new Web3(window.web3.currentProvider);
        }

        const [user] = await window.ethereum.request({ method: 'eth_requestAccounts' });
        // setIsShowCheckout(true);
        history.push(`/cart/${res.data.order.id}/land`)
        let landRes = null;
        if (typeCoin == "USDT") {
            //landRes = await landContract(USDT_CONTRACT).methods.transfer(ADDRESS_RECEIVER, res.data.order.total_amount).send({ from: user, gas: GAS });
            //landRes = await landContract(USDT_CONTRACT).methods.transfer(ADDRESS_RECEIVER, handlePrice(1,18)).send({ from: user });
            landRes = await landContract(USDT_CONTRACT).methods.transfer(ADDRESS_RECEIVER, handlePrice(res.data.order.total_amount, 6)).send({ from: user, gas: GAS });
        } else {
            //landRes = await usdcContract(USDC_CONTRACT).methods.transfer(ADDRESS_RECEIVER, res.data.order.total_amount).send({ from: user, gas: GAS });
            //landRes = await usdcContract(USDC_CONTRACT).methods.transfer(ADDRESS_RECEIVER, handlePrice(1,6)).send({ from: user });
            landRes = await usdcContract(USDC_CONTRACT).methods.transfer(ADDRESS_RECEIVER, handlePrice(res.data.order.total_amount, 6)).send({ from: user, gas: GAS });
        }
        cartAction.setTranshash(landRes.transactionHash)
        // setTransactionHash(landRes.transactionHash);
        // setIsShowCheckout(true)

    }

    const handleCheckoutAvartar = async () => {
        setTransactionHash("")
        const avatar = [];
        const pack = [];
        avatarItem.map(m => {
            if (m.product.product_type == 2) {
                const item = {
                    "product_id": m.product.id,
                    "amount": m.amount
                }
                avatar.push(item);
            }
            if (m.product.product_type == 3) {
                const item = {
                    "product_id": m.product.id,
                    "amount": m.amount
                }
                pack.push(item);
            }

        })
        const body = {
            "currency": "NTVRK",
            "avatars": avatar,
            "packs": pack
        }
        const res = await checkoutService.avartarCheckout(body)
        setOrder(res.data.order)
        //  handl checkout
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum);
        } else if (window.web3) {
            window.web3 = new Web3(window.web3.currentProvider);
        }
        const [user] = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setIsShowCheckout(true)
        // const landRes = await netrvkContract(NETVRK_CONTRACT).methods.transfer(ADDRESS_RECEIVER, handlePrice(1,18)).send({ from: user });
        const landRes = await netrvkContract(NETVRK_CONTRACT).methods.transfer(ADDRESS_RECEIVER, handlePrice(res.data.order.total_amount,8)).send({ from: user, gas: GAS });
        setTypeSubmit("avatar")
        setTransactionHash(landRes.transactionHash);

    }

    const test=()=>{
        
    }
    return (
        <div className='wrap'>
            {isShowCheckout ? <Checkout setIsShowCheckout={handleSetShowModal} tranHash={transactionhash} order={order} typeSubmit={typeSubmit} refresh={handlRefresh} /> : ""}
            <div className='body'>
                <div className='header-cart'>
                    <div className='title-header'>
                        <ShoppingCartOutlined /> {t('checkout.cart')}
                    </div>
                    <div className='button-rm-all' onClick={() => handleRemoveAll()}>
                        X {t('checkout.remove_all')}
                    </div>
                </div>
                <div className='body-cart'>
                    <div className='justify-content-center warning-alert'>
                     <div>
                            <img src={images.ic_warning} style={{ width: 24, height: 24 }} />{' '}
                            {/* {t('popup.quota')} */}
                            Warning: Make sure your wallet balance is sufficient before checking out.
                        </div>
                    </div>
                    {listCart && listCart.map((item, index) => (
                        <div className='table' key={index}>
                            <div className='title-table'>
                                {/* {item.title == "AVATARS & BONUS PACK" ? t('checkout.avatars_bonus_pack') : t('checkout.virtualland')} */}
                                <div className='title-row'>
                                    <div className='item-pack'>item</div>
                                    <div className='item-amount'>{t('checkout.amount')}</div>
                                    <div className='item-price'>{t('checkout.price')}</div>
                                </div>
                                {item.data.map((itemCol, indexCol) => (
                                    <div key={indexCol} className='col-table'>
                                        <div className='item-pack'>
                                            <img src={URL_LAND + itemCol.product.image.trim()} />
                                            {itemCol.product.name}
                                            <div className='out-stock'>
                                                {/* <CloseCircleOutlined style={{width: "20px"}}/> Hết hàng */}

                                            </div>
                                        </div>
                                        <div className='item-amount'>
                                            <div className='box-amount' style={{ background: item.title == "AVATARS & BONUS PACK" || userLevel.data == "unlimited" || userLevel.message == "double" ? "" : "#FFF" }}>
                                                <div style={{ display: item.title != "AVATARS & BONUS PACK" || userLevel.data == "unlimited" || userLevel.message == "double" ? "unset" : "none" }} onClick={() => { handleMinus(itemCol.product.id, item.title) }}>-</div>
                                                <div className={validate.includes(itemCol.product.name)?"number-highlighted number":'number'}>
                                                    {handleGetData(itemCol.product.id, item.title, "amount")}
                                                </div>
                                                <div style={{ display: item.title != "AVATARS & BONUS PACK" || userLevel.data == "unlimited" || userLevel.message == "double" ? "unset" : "none" }} onClick={() => { handlePlus(itemCol.product.id, item.title) }}>+</div>
                                            </div>

                                        </div>
                                        <div className='item-price'>
                                            <div className='price'>
                                                {handleGetData(itemCol.product.id, item.title, "totalPrice")}  {item.title != "AVATARS & BONUS PACK" ? "USDC/USDT" : "NTVRK"}
                                            </div>
                                            <div className='item-rm' onClick={() => { handleRemoveItem(item.title, itemCol.product.id) }}>X {t('checkout.remove')}</div>
                                        </div>

                                    </div>
                                ))}
                                <div className='total'>
                                    <div className='item-pack'>{t('checkout.total')}</div>
                                    <div className='item-amount'>{getTotal(item.title, "amount")}</div>
                                    <div className='item-price'>{getTotal(item.title, "totalPrice")}
                                        {item.title == "AVATARS & BONUS PACK" ?

                                            " NTVRK"
                                            :
                                            <Select defaultValue={typeCoin} style={{ width: "85px", marginLeft: "10px" }} onChange={handleChange}>
                                                <Option value="USDT">USDT</Option>
                                                <Option value="USDC">USDC</Option>
                                            </Select>
                                        }
                                    </div>

                                </div>
                                <div className='col-btn' >
                                    {item.title == "AVATARS & BONUS PACK" && <div style={{ marginRight: "10px", background: "#EBECF0" }} className='btn-checkout' key={item.title} onClick={() => setOpenExchangeModal(true)}>
                                        {t('checkout.buyntvrk')}
                                    </div>}
                                    <div className='btn-checkout' key={item.title} onClick={() => handleCheckout(item.title)}>
                                        {t('checkout.checkout')}

                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {openExchangeModal && <Box className="popup-exchange">
                <Box className="popup-sub-exchange">
                    <iframe
                        title='uniswap'
                        src={`https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=${outputCurrency}`}
                        style={{ width: '100%', height: 580, border: 0 }}
                    />
                    <Box className='justify-content-center'>
                        <Button
                            variant='contained'
                            style={{ width: 240 }}
                            onClick={() => {
                                setOpenExchangeModal(false);
                            }}
                        >
                            BACK
                        </Button>
                    </Box>
                </Box>
            </Box>}

        </div>
    )
}
export default Cart;
