import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Container, Typography, Paper, FormGroup, TextField } from '@material-ui/core';
import { Col, Row } from 'antd';
import { ColorButton, Loading } from 'components';
import { profileAction } from 'actions/profile';
import { userService } from 'services/user';
import { privateRoute } from 'routes';
import { UserAvatar } from 'views/Profile/components';

import CheckOutlined from '@material-ui/icons/CheckOutlined';

const ProfileUpdate = () => {
  const history = useHistory();
  const { ...profile } = useSelector(({ profile }) => profile);

  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const [isLoading, setIsLoading] = React.useState(false);

  const handleClickUpdate = () => {
    handleSubmit(({ username, bio }) => {
      const formData = new FormData();
      formData.append('username', username.trim());
      formData.append('bio', bio.trim());

      setIsLoading(true);
      userService
        .updateProfile(formData)
        .then(() => {
          profileAction.fetchProfile();
          history.push(privateRoute.profile.path);
        })
        .finally(() => {
          setIsLoading(false);
        });
    })();
  };

  return (
    <Container maxWidth='md'>
      <Paper className='p-24'>
        <Typography variant='h4' gutterBottom>
          Update Profile
        </Typography>
        <Row gutter={24}>
          <Col md={8} sm={24} className='text-align-center pt-20'>
            <UserAvatar editable />
          </Col>

          <Col md={16} sm={24}>
            <FormGroup>
              <Controller
                name='username'
                defaultValue={profile.username}
                control={control}
                rules={{
                  validate: {
                    required: (value) => value.trim() !== '' || 'Name cannot be empty',
                    minLength: (value) => value.trim().length >= 5 || 'Name is at least 5 characters',
                    maxLength: (value) => value.trim().length <= 40 || 'Name is at most 40 characters',
                  },
                }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField {...field} required label='Name' error={invalid} helperText={error?.message} />
                )}
              />

              <Controller
                name='bio'
                defaultValue={profile.bio}
                control={control}
                rules={{
                  validate: {
                    maxLength: (value) => value.trim().length <= 160 || 'Biography is at most 160 characters',
                  },
                }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    multiline
                    rows={5}
                    label='Biography'
                    error={invalid}
                    helperText={error?.message}
                  />
                )}
              />
            </FormGroup>

            <ColorButton
              variant='outlined'
              disabled={isLoading}
              startIcon={<Loading icon={<CheckOutlined />} visible={isLoading} />}
              onClick={handleClickUpdate}
            >
              Update
            </ColorButton>
          </Col>
        </Row>
      </Paper>
    </Container>
  );
};

export default ProfileUpdate;
