import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { PrivateLayout } from 'layouts';
import { AppTheme, AppError } from 'containers';
import { store } from 'reducers';
import { ConfigProvider as AntTheme } from 'antd';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { default as ScrollMemory } from 'react-router-scroll-memory';
import { default as MomentUtils } from '@date-io/moment';
import * as config from 'config';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import common_en from './common_en.json';
import common_zht from './common_zht.json';
import common_zhs from './common_zhs.json';

config.run();
i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en: {
      common: common_en,
    },
    zht: {
      common: common_zht,
    },
    zhs: {
      common: common_zhs,
    },
  },
});

const App = () => {
  const [isReady, setIsReady] = React.useState(false);
  React.useEffect(() => {
    setIsReady(true);
  }, []);

  return (
    isReady && (
      <ReduxProvider store={store}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <I18nextProvider i18n={i18next}>
            <AntTheme componentSize='large'>
              <AppTheme>
                <Router>
                  <ScrollMemory />
                  <AppError>
                    <Switch>
                      <Route path='/' component={PrivateLayout} />
                    </Switch>
                  </AppError>
                </Router>
              </AppTheme>
            </AntTheme>
          </I18nextProvider>
        </MuiPickersUtilsProvider>
      </ReduxProvider>
    )
  );
};

export default App;
