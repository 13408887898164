import { client } from './axios';

const searchItem = (params) => client.get(`/v1/nft/nfts`, { params });
const getItem = ({ id }) => client.get(`/v1/nft/nfts/${id}`);
const createItem = (body) => client.post(`/v1/nft/nfts`, body);
const createItemCollection = (body) => client.post(`/v1/nft/nfts/sub-collection`, body);

const createSale = (body) => client.post(`/v1/nft/sales`, body);
const removeSale = ({ id }) => client.delete(`/v1/nft/sales/${id}`);

const buy = ({ saleId }) => client.post(`/v1/nft/sales/${saleId}/buy`);
const bid = ({ saleId, ...body }) => client.post(`/v1/nft/sales/${saleId}/bids`, body);
const confirmBid = ({ saleId, bidId }) => client.post(`/v1/nft/sales/${saleId}/bids/${bidId}/buy`);
const getHistoryBids = ({ saleId }) => client.get(`/v1/nft/sales/${saleId}/bids`);

const fetchTopSellers = (params) => client.get(`/v1/nft/sales/top-sellers`, { params });
const fetchRelatedItem = (params) => client.get(`/v1/nft/nfts/related-nfts`, { params });

const fetchSubCollections = () => client.get(`/v1/nft/sub-collections`);
const createCollection = (body) => client.post(`/v1/nft/collections`, body);

export const marketService = {
  searchItem,
  getItem,
  createItem,
  createItemCollection,

  createSale,
  removeSale,

  buy,
  bid,
  confirmBid,
  getHistoryBids,

  fetchTopSellers,
  fetchRelatedItem,

  fetchSubCollections,
  createCollection,
};
