
import { ActionType } from 'reducers/loobox/common';
import { store } from 'reducers';


const updateOrderCount = () => {
    store.dispatch({
      type: ActionType.COUNT_ORDER,
    });
  };
  const setTranshash = (data) => {
    store.dispatch({
      type: ActionType.TRANS_HASH,
      data
    });
  };

  export const cartAction = {
    updateOrderCount,setTranshash
  };


  