import { Link } from 'react-router-dom';
import { Container, Typography, Link as NavLink, Box, IconButton, Hidden } from '@material-ui/core';
import { privateRoute } from 'routes';
import { useTranslation } from 'react-i18next';
import { images } from 'assets/images';

const Footer = () => {

  const { t, i18n } = useTranslation('common');

  return (
    <>
      <Box className='containerBottom'>
        <Container className='flex-row flex-wrap'>
          <Box className='align-items-top col-sm-3'>
            <NavLink color='inherit' component={Link} to={privateRoute.home.path} className='mr-32'>
              <Hidden xsDown>
                <img src='/assets/icons/logo-black.svg' height={40} className='mr-40' />
              </Hidden>
            </NavLink>            
          </Box>
          <Box className='align-items-center col-sm-2 footer-menu'>
            <h2>Menu</h2>
            <a className="footer_link" href="https://spores.app/explore">{t('footer.marketplace')}</a>
            {/* <a className="footer_link" href={privateRoute.launchpad.path}>{t('footer.launchpad')}</a> */}
            <a className="footer_link" href="https://staking.spores.app/">{t('footer.staking')}</a>
          </Box>          
          <Box className='align-items-center col-sm-2 footer-menu'>
            <h2>Community</h2>
            <a className="footer_link" href="https://spores.nolt.io/">{t('footer.suggest')}</a>
          </Box>          
          <Box className='align-items-center col-sm-2 footer-menu'>
            <h2>Legal</h2>
            <a className="footer_link" href="https://staking.spores.app/terms-of-use">{t('footer.term')}</a>
            <a className="footer_link" href="https://staking.spores.app/privacy-policy">{t('footer.privacy')}</a>
            <a className="footer_link" href="https://staking.spores.app/cookie-statement">{t('footer.cookie')}</a>
          </Box>
          <Box className="col-sm-3 footer-contact">
            <h2>Contact</h2>
            <div className="row col-12">
              <a className="footer_link" href="https://t.me/sporeseng"><img src={ images.s_1 } /></a>
              <a className="footer_link" href="https://spores.medium.com/"><img src={ images.s_2 } /></a>
              <a className="footer_link" href="https://www.linkedin.com/company/sporesnetwork/"><img src={ images.s_3 } /></a>
              <a className="footer_link" href="https://twitter.com/Spores_Network/"><img src={ images.s_4 } /></a>
            </div>
            <div className="row col-12" style={{marginTop: "20px"}}>
              <a className="footer_link" href="https://www.youtube.com/channel/UC9pgjF_aWprnVodvxO7OPkA/about/"><img src={ images.s_5 } /></a>
              <a className="footer_link" href="https://www.facebook.com/SporesNetwork/"><img src={ images.s_6 } /></a>
              <a className="footer_link" href="https://www.instagram.com/SporesNetwork/"><img src={ images.s_7 } /></a>
              <a className="footer_link" href=""><img src={ images.s_8 } /></a>
            </div>
            <NavLink className='footer_link d-none'>
              <Typography>{t('footer.marketplace')}</Typography>
            </NavLink>               
            {/* <NavLink className='footer_link d-none' component={Link} to={privateRoute.launchpad.path} >
              <Typography>{t('footer.launchpad')}</Typography>
            </NavLink>                */}
            <NavLink className="d-none">
              <Typography>{t('footer.contactus')}</Typography>
            </NavLink>            
          </Box>
        </Container>
      </Box>      
    </>
  );
};

export default Footer;
