export const ActionType = {
    USER_LOGIN_LOOTBOX: 'USER_LOGIN_LOOTBOX',
    USER_FETCH_PROFILE_LOOTBOX: 'USER_FETCH_PROFILE_LOOTBOX',
    USER_LOGOUT_LOOTBOX: 'USER_LOGOUT_LOOTBOX',
    EXPLORE: 'EXPLORE',
  };
  const initState ={
    ...localStorage.getItem('profile_lootbox')?JSON.parse(localStorage.getItem('profile_lootbox')):"",
    isLoggedIn:  localStorage.getItem('profile_lootbox')?true: false,
    showModalExplore: false
  }
  
  function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};
  
  const profileLootbox = (state = {...initState}, { type, data }) => {
    switch (type) {
      case ActionType.USER_LOGIN_LOOTBOX:
        const {access_token } = data;
        const jwtJson  = parseJwt(access_token);
        localStorage.setItem('jwtJson', JSON.stringify(jwtJson));
        localStorage.setItem('profile_lootbox', JSON.stringify(data));
        return { ...data, isLoggedIn: true };
      case ActionType.USER_FETCH_PROFILE_LOOTBOX:
        const profile = { ...state, ...data };
        // localStorage.setItem('profile_lootbox_pro', JSON.stringify(profile));
        return profile;
      case ActionType.USER_LOGOUT_LOOTBOX:
        localStorage.removeItem('profile_lootbox');
        return {};
      case ActionType.EXPLORE:
        return { ...state,showModalExplore:data};
      default:
        return state;
    }
  };
  
  export default profileLootbox;
  