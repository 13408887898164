import { client } from './axios';

const nonce = (params) => client.get(`/v1/account/auth/nonce`, { params });
const login = (body) => client.post(`/v1/account/auth/login`, body);

const getProfile = (body) => client.get(`/v1/account/users`, body);
const updateProfile = (body) => client.put(`/v1/account/users`, body);

const getProfileArtist = ({ user }) => client.get(`/v1/account/users/${user}`);

export const userService = {
  nonce,
  login,

  getProfile,
  updateProfile,

  getProfileArtist,
};
