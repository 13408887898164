/* eslint-disable react-hooks/exhaustive-deps */
import  { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router';
import { images } from 'assets/images';
import {checkoutService} from "services/lootbox/checkout";
import { useTranslation } from 'react-i18next';
import { Tooltip, Button } from 'antd';

function CountDown(props) {
  const { t, i18n } = useTranslation('common');
  const [durationDiff, setDurationDiff] = useState(null);
  const history = useHistory();
  const [userJwt, setUserJwt] = useState(null);
  const [timeCount, setTimeCount] = useState(0);
  const [useLevel,setUserLevel] = useState(null);
  useEffect(async() => {
    const jwtJson = localStorage.getItem("jwtJson");
    if(jwtJson != null && jwtJson != undefined){
      setUserJwt(JSON.parse(jwtJson))
    }
    const res = await checkoutService.getUserLever();
    let time =0 ;
    setUserLevel(res.level_infor)
    if(res && res && res.message !== "0"){
      // setTimeCount(parseInt(res.message));
      time = parseInt(res.message);
    }
    if(time > 0){
      countDownTime(time);
    }
    
  }, []);

  const {data} = props
  function countDownTime(time) {
    let diffTime = time *1000;
    if (diffTime >= 0) {
      let ress = moment.duration(diffTime).asMilliseconds();
      setInterval(function () {
        ress = ress - 1000;
        if (ress >= 0) {
          setDurationDiff(ress);
        } else {
          setDurationDiff(0);
        }
      }, 1000);
    }
  }

  let element = <div className="tooltip-permision">{t('countdown.youcanbuy')}<br />
  <ul>
  {useLevel && useLevel.product_can_buy && useLevel.product_can_buy.map((m,key) =>{
    return <li key={key}> {m.max_amount} {m.name}</li>
  })}
    
  </ul>
  </div>;

  return (
    <Fragment>
       {durationDiff != 0 && durationDiff != null ?
        <div className='count-down'>
          <h2 className="text-welcome">{t('countdown.welcome')}</h2>
          <div className='time-countdown'>
            <h3 className='sub-title'>SALE STARTS IN</h3>
            <div>
              <div className='item-time'>
                <div className='box-time'>{moment.duration(durationDiff).days()}</div>
                Days
              </div>
              <div className='dot'>:</div>
              <div className='item-time'>
                <div className='box-time'>{moment.duration(durationDiff).hours()}</div>
                Hours
              </div>
              <div className='dot'>:</div>
              <div className='item-time'>
                <div className='box-time'>{moment.duration(durationDiff).minutes()}</div>
                Minutes
              </div>
              <div className='dot'>:</div>
              <div className='item-time'>
                <div className='box-time'>{moment.duration(durationDiff).seconds()}</div>
                Seconds
              </div>
            </div> 
          </div>
        </div>
        :
        <div className='count-down'>
            <h2 className="text-welcome">{t('countdown.welcome')}</h2>
            {useLevel && useLevel.id != 9 &&<Tooltip placement="bottom" overlayInnerStyle={{ minWidth: '260px', backgroundColor: 'white', color: "black" }} title={element}>
              <div className="text-login-vip"><span style={{ color: '#6B718C', fontSize: 20, fontWeight: 600 }}>{t('countdown.youare')} {" "}</span><span style={{ color: '#F34F24' }}>VIP level {useLevel && useLevel.id}</span></div>
            </Tooltip> }
          </div>
          }
        </Fragment>
  
  );
}
export default CountDown;
