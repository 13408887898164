import React from 'react';
import { Box, Button, Slider } from '@material-ui/core';
import { AvatarEditor, ColorButton, Loading } from 'components';
import { userService } from 'services/user';

import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';

const UserAvatarCrop = ({ file, onSuccess, onCancel }) => {
  const inputEl = React.useRef();
  const [scale, setScale] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(false);
  const [innerWidth, setInnerWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const image = React.useMemo(
    () => ({
      width: innerWidth > 536 ? 470 : innerWidth - 64,
      height: innerWidth > 536 ? 470 : innerWidth - 64,
      border: 1,
      borderRadius: 235,
    }),
    [innerWidth],
  );

  const handleClickSave = async () => {
    if (inputEl) {
      const canvasScaled = inputEl.current.getImage();
      const imageUrl = canvasScaled.toDataURL();
      const blob = await fetch(imageUrl).then((res) => res.blob());

      const formData = new FormData();
      formData.append('avatar', new File([blob], file.name));

      setIsLoading(true);
      userService
        .updateProfile(formData)
        .then(() => {
          onSuccess();
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Box>
      <Box className='mb-12'>
        <AvatarEditor ref={inputEl} image={file} scale={scale} {...image} />
        <Slider defaultValue={1} step={0.001} min={1} max={2} onChange={(_, value) => setScale(value)} />
      </Box>
      <Box className='justify-content-end Button-Line'>
        <Button variant='outlined' onClick={onCancel}>
          Cancel
        </Button>
        <ColorButton
          variant='outlined'
          disabled={isLoading}
          startIcon={<Loading visible={isLoading} icon={<CheckOutlinedIcon />} />}
          onClick={handleClickSave}
        >
          Update
        </ColorButton>
      </Box>
    </Box>
  );
};

export default UserAvatarCrop;
