import { client } from './axios';

const order = (params) => client.get(`/order`, { params });
const checkout = (body) => client.post(`/order/checkout`, body);
const avartarCheckout =(body)=> client.post('/avatar_pack/card/checkout', body)
const submitLand = (body) => client.post("/virtual_land/card/submit",body)
const submitavatar = (body) => client.post("/avatar_pack/card/submit",body)
const historyOrder = (id) => client.get(`/order/history/${id}`)
//land
const landCheckout =(body)=> client.post('virtual_land/card/checkout', body)
const getUserLever =()=> client.get("/userlevel")

export const checkoutService = {
    order,
    checkout,
    landCheckout,
    submitLand,
    avartarCheckout,
    submitavatar,
    historyOrder,
    getUserLever
};
