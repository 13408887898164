import { client } from './axios';

const nonce = (params) => client.get(`/login/wallet`, { params });
const login = (body) => client.post(`/login`, body);
const register = (body) => client.post(`register`, body);
const getProfile = (params) => client.get(`/account/detail`, params);
const updateProfile = (body) => client.put(`/account/detail`, body);

export const userServiceLb = {
  nonce,
  login,
  register,
  updateProfile,
  getProfile
};
