import './style.scss'
import success from 'assets/images/success-ic.png'
import {Modal } from 'antd';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

function Thankyou(params) {
    const { t, i18n } = useTranslation('common');    
    const {setThankModal} = params;
    const [showModal, setShowModal] = useState(true);
    const handle=()=>{
        setThankModal(false)
    }
    return(
        <Fragment>
            <Modal
            visible={showModal}
            onCancel={() => handle(false)}
            footer={null}
            closable={false}
            bodyStyle={{ background: 'transparent', padding:0 }}
        >
            <div className="thank-modal">
            <img src={success} width="68px" height="68px" alt="" />
            <div className="thankyou">
                {t('checkout.thank_you')}
            </div>
            <div className="submission">
                {t('checkout.you_submission')}
            </div>
        </div>
        </Modal>
        </Fragment>
    )
}
export default Thankyou
