import React from 'react';
import { Button, Typography, Box } from '@material-ui/core';
import { Paper, List, ListItem, CircularProgress, Dialog } from '@material-ui/core';
import { Dropdown, notification } from 'antd';
import { profileAction } from 'actions/profile';
import { coreuiAction } from 'actions/coreui';
import { color } from 'utils/constants';
import { MAINNET } from 'env';

import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';

export const NETWORK_BARS = MAINNET
  ? [
      {
        id: 1,
        image: <img src='/assets/icons/logo-ethereum.svg' style={{ height: 28, width: 28 }} />,
        icon: <img src='/assets/icons/logo-ethereum-white.svg' style={{ height: 24 }} />,
        backgroundImage: '/assets/images/eth-background.png',
        color: '#fff',
        name: 'Ethereum Mainnet',
        chainId: '0x1',
      }
    ]
  : [
      {
        id: 1,
        image: <img src='/assets/icons/logo-ethereum.svg' style={{ height: 28, width: 28 }} />,
        icon: <img src='/assets/icons/logo-ethereum-white.svg' style={{ height: 24 }} />,
        backgroundImage: '/assets/images/eth-background.png',
        color: '#fff',
        name: 'Ethereum Rinkeby',
        chainId: '0x4',
      },
      {
        id: 1,
        image: <img src='/assets/icons/logo-ethereum.svg' style={{ height: 28, width: 28 }} />,
        icon: <img src='/assets/icons/logo-ethereum-white.svg' style={{ height: 24 }} />,
        backgroundImage: '/assets/images/eth-background.png',
        color: '#fff',
        name: 'Ethereum Mainnet',
        chainId: '0x1',
      }
    ];

export const NETWORK_MAPS = {
  '0x1': '/erc20',
  '0x4': '/erc20',
  '0x38': '/bep20',
  '0x61': '/bep20',
};

const NetworkBar = () => {
  const [chainId, setChainId] = React.useState(window.ethereum?.chainId);

  const handleChangeChainId = async (item) => {
    if (!window.ethereum) {
      return profileAction.requireMetaMask();
    }
    try {
      if (item.chainId !== window.ethereum.chainId)
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: item.chainId }],
        });
      else {
        setChainId(item.chainId);
      }
    } catch (error) {
      if (error.code === 4902) {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainName: item.name,
              chainId: item.chainId,
              rpcUrls: item.rpcUrls,
            },
          ],
        });
      }
    }
  };

  React.useEffect(() => {
    window.ethereum?.on('chainChanged', (chainId) => {
      setChainId(chainId);
      coreuiAction.updateChainId(chainId);
      notification.success({ message: 'Network switched' });
    });
  }, []);

  const networkChoose = NETWORK_BARS.find((item) => item.chainId === chainId) || {
    image: <BlockOutlinedIcon style={{ width: 22, height: 22, margin: 3, color: color.error }} />,
    name: 'Wrong network',
  };

  return (
    <>
      <Dropdown
        trigger='click'
        getPopupContainer={(event) => event.parentNode}
        overlay={
          <div>
            <List disablePadding component={Paper} style={{ marginTop: 10 }}>
              {NETWORK_BARS.map((item) => (
                <ListItem
                  key={item.id}
                  button
                  style={{ padding: '5px 15px' }}
                  selected={item.chainId === networkChoose.chainId}
                  onClick={() => handleChangeChainId(item)}
                >
                  {item.image} {item.name}
                </ListItem>
              ))}
            </List>
          </div>
        }
      >
        <Button variant='outlined' endIcon={<ArrowDropDownOutlinedIcon />} style={{ minWidth: 140 }}>
          {networkChoose.image} {networkChoose.name}
        </Button>
      </Dropdown>

      <Dialog
        open={Boolean(chainId) && !NETWORK_BARS.some((item) => item.chainId === chainId)}
        PaperProps={{ style: { width: 360 } }}
      >
        <Box className='flex-column align-items-center'>
          <CircularProgress className='mb-12' />
          <Typography variant='h5'>Wrong network</Typography>
          <Typography color='textSecondary' paragraph>
            Change network to
          </Typography>
          <Box className='flex-column'>
            {NETWORK_BARS.map((item) => (
              <Button
                key={item.id}
                onClick={() => handleChangeChainId(item)}
                style={{
                  margin: '8px 0px',
                  padding: '12px 24px',
                  backgroundImage: `url(${item.backgroundImage})`,
                  backgroundSize: 'cover',
                  color: item.color,
                }}
              >
                <Typography variant='subtitle1' className='align-items-center justify-content-center px-8'>
                  {item.icon} <span className='px-8'>{item.name}</span>
                </Typography>
              </Button>
            ))}
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default NetworkBar;
