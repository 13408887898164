import Web3 from 'web3';

import { abi as land } from './abis/Land.json';

import { abi as netrvk } from './abis/NETRVK.json';

import { abi as usdc } from './abis/USDC.json';

const web3 = () => new Web3(window.ethereum);
export const landContract = (address) => new (web3().eth.Contract)(land, address);

export const netrvkContract = (address) => new (web3().eth.Contract)(netrvk, address);

export const usdcContract = (address) => new (web3().eth.Contract)(usdc, address);

