import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { AppHeader, AppFooter, AppFeedback } from 'containers';
import { systemAction } from 'actions/system';
import { profileAction } from 'actions/profile';
import { systemService } from 'services/system';
import { privateRoute } from 'routes';
import { jwtDecode } from 'utils/common';
import { lbprofileAction } from "actions/lootbox/profile";
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';

const PrivateLayout = () => {
  const { isLoggedIn } = useSelector(({ profileLootbox }) => profileLootbox);
  const history = useHistory();

  React.useEffect(() => {
    window.ethereum?.on('accountsChanged', lbprofileAction.signOut);
  }, []);

  React.useEffect(() => {
    try {
      const profile = JSON.parse(localStorage.getItem('profile_lootbox'));
      const payload = jwtDecode(profile.access_token);
      if (payload.exp - 3600 * 0.1 < Date.now() / 1000) {
        lbprofileAction.signOut()
      }
    } catch { }
  }, []);

  React.useEffect(() => {
    if (isLoggedIn) {
      const jwt = localStorage.getItem('jwtJson')
      const jwtJson = JSON.parse(jwt);
      if (jwtJson != null && jwtJson != undefined) {
        const orderId = localStorage.getItem(jwtJson.wallet_address + "_order");
        const time = localStorage.getItem(jwtJson.wallet_address + "_time");
        if (orderId && time) {
          const currentTime = moment().unix();
          const tem = currentTime - parseInt(time);
          if (tem <= 900) {
            history.push(`/cart/${orderId}/land`)
          } else {
            localStorage.removeItem(jwtJson.wallet_address + "_order");
            localStorage.removeItem(jwtJson.wallet_address + "_time");
          }
        }

      }
    }
  }, [isLoggedIn])

  return (
    <div className='App Private-Layout'>
    <AppHeader />
    <AppFeedback />
    <div className='App-Body'>
      <Switch>
        {Object.values(privateRoute)
          .filter(({ requiredLogin }) => !requiredLogin || isLoggedIn)
          .map(({ path, component }) => (
            <Route exact key={path} path={path} component={component} />
          ))}
        <Redirect from='/' to={privateRoute.explore.path} />
      </Switch>
    </div>
    <AppFooter />
  </div>
  );
};

export default PrivateLayout;
