import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Typography, Tabs, Tab, Button, Container, Chip } from '@material-ui/core';
import { userService } from 'services/user';
import { privateRoute } from 'routes';
import { parse, stringify } from 'query-string';
import { UserAvatar, UserCover, UserItems, UserCollections } from './components';

import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

const Profile = () => {
  const { id: artistAddr } = useParams();
  const profile = useSelector(({ profile }) => profile);

  const [artistProfile, setArtistProfile] = React.useState({});

  const fetchData = React.useCallback(() => {
    if (artistAddr)
      userService.getProfileArtist({ user: artistAddr }).then(({ data }) => {
        setArtistProfile(data);
      });
    else {
      setArtistProfile(profile);
    }
  }, [artistAddr, profile]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return <Artist address={artistAddr ?? profile.id} artist={artistProfile} isProfile={!artistAddr} />;
};

const Artist = ({ address: user, artist, isProfile }) => {
  const location = useLocation();
  const { tab, ...query } = parse(location.search);

  const [counter, setCounter] = React.useState({});

  const paramsOwned = React.useMemo(() => ({ ownerAddr: user }), [user]);
  const paramsAuction = React.useMemo(() => ({ ownerAddr: user, saleType: [1] }), [user]);
  const paramsFixed = React.useMemo(() => ({ ownerAddr: user, saleType: [0] }), [user]);
  const paramsCreated = React.useMemo(() => ({ creatorAddr: user }), [user]);

  const onUpdateOwned = React.useCallback((num) => setCounter((cur) => ({ ...cur, owned: num })), []);
  const onUpdateAuction = React.useCallback((num) => setCounter((cur) => ({ ...cur, auction: num })), []);
  const onUpdateFixed = React.useCallback((num) => setCounter((cur) => ({ ...cur, fixed: num })), []);
  const onUpdateCreated = React.useCallback((num) => setCounter((cur) => ({ ...cur, created: num })), []);
  const onUpdateCollection = React.useCallback((num) => setCounter((cur) => ({ ...cur, collection: num })), []);

  const tabs = [
    {
      id: 1,
      code: 'owned',
      label: 'Owned',
      component: <UserItems params={paramsOwned} onUpdateCounter={onUpdateOwned} />,
    },
    {
      id: 2,
      code: 'auction',
      label: 'On auction',
      component: <UserItems params={paramsAuction} onUpdateCounter={onUpdateAuction} />,
    },
    {
      id: 3,
      code: 'fixed',
      label: 'Fixed price',
      component: <UserItems params={paramsFixed} onUpdateCounter={onUpdateFixed} />,
    },
    {
      id: 4,
      code: 'created',
      label: 'Created',
      component: <UserItems params={paramsCreated} onUpdateCounter={onUpdateCreated} />,
    },
  ].concat(
    isProfile
      ? [
          {
            id: 5,
            code: 'collection',
            label: 'Collections',
            component: <UserCollections onUpdateCounter={onUpdateCollection} />,
          },
        ]
      : [],
  );
  const [activeTab, setActiveTab] = React.useState((tabs.find((item) => item.code === tab) ?? tabs[0]).code);

  const handleChangeTab = (_, nextTab) => {
    setActiveTab(nextTab);
    window.history.replaceState(null, null, '?' + stringify({ ...query, tab: nextTab }));
  };

  return (
    <Box>
      <UserCover user={artist} editable={isProfile} />
      <Container>
        <Box px={3} className='flex-row align-items-start'>
          <UserAvatar user={artist} editable={isProfile} />
          <Box zIndex={1} flex={1}>
            <Box style={{ height: 120, padding: '40px 0px 0px 16px', color: '#fff' }}>
              <Typography variant='h4'>{artist.username}</Typography>
              <Typography component='code'>{artist.address}</Typography>
            </Box>
            <Box className='justify-content-between' style={{ padding: '24px 0px 0px 16px' }}>
              <Typography variant='body2' color='textSecondary' className='linebreak' style={{ maxWidth: 420 }}>
                {artist.bio}
              </Typography>

              {isProfile && (
                <Link to={privateRoute.profileUpdate.path}>
                  <Button variant='contained' startIcon={<EditOutlinedIcon />}>
                    Edit Profile
                  </Button>
                </Link>
              )}
            </Box>
          </Box>
        </Box>

        <Box mt={2}>
          <Tabs value={activeTab} onChange={handleChangeTab}>
            {tabs.map((tab) => {
              const selected = tab.code === activeTab;
              return (
                <Tab
                  key={tab.id}
                  value={tab.code}
                  style={{ minWidth: 120 }}
                  label={
                    <Box className='flex-row'>
                      <Typography className='mr-8'>{tab.label}</Typography>
                      <Chip
                        size='small'
                        variant='default'
                        color={selected ? 'primary' : 'default'}
                        style={{ borderRadius: 4 }}
                        label={counter[tab.code] ?? 0}
                      />
                    </Box>
                  }
                />
              );
            })}
          </Tabs>
          {tabs.map((tab) => (
            <Box key={tab.id} hidden={tab.code !== activeTab} py={2}>
              {tab.component}
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Profile;
