import { useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { List, ListItem, ListItemText, Hidden, Paper, Link as NavLink } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Dropdown } from 'antd';
import { color } from 'utils/constants';
import { privateRoute } from 'routes';
import { images } from 'assets/images';
import { useEffect, useState } from 'react';

const Menu = ({ onClickMenu }) => {
  const classes = useStyles();
  const { payments } = useSelector(({ system }) => system);
  const {count} = useSelector(({common}) => common)
  const { explore, profileUpdate, launchpad } = privateRoute;
  const { isLoggedIn, mintable = false } = useSelector(({ profileLootbox }) => profileLootbox);
  const [countOrder, setCountOrder] = useState(0)
  const MenuItem = ({ visible = true, name, path, onClick }) => {
    const location = useLocation();
    return visible ? (
      <ListItem
        button
        component={Link}
        to={path ?? '/'}
        className={classes.item}
        selected={location.pathname.startsWith(path)}
        onClick={() => {
          if (onClick) onClick();
          else if (onClickMenu) onClickMenu();
        }}
      >
        <ListItemText primary={name} />
      </ListItem>
    ) : null;
  };
  useEffect(()=>{
    let count = 0
    const land = localStorage.getItem("card_checkout_land");
    const avartar = localStorage.getItem("card_checkout_avatar");
    if(land != null && land != undefined){
      
      count = count + JSON.parse(land).length
    }
    if(avartar != null && avartar != undefined){
      count = count + JSON.parse(avartar).length
    }
    setCountOrder(count);
  },[count,isLoggedIn])

  return (
    <>
      {/* <MenuItem {...explore} name='Marketplace' />
      <MenuItem {...profileUpdate} name='Loot box' />
      <MenuItem {...launchpad} name='Launchpad' /> */}
     <ListItem button className={classes.item} component={NavLink} color='inherit' href='/cart'>
        <img src={images.ic_cart} style={{ width: 34, height: 34 }} />
        {
          countOrder && countOrder != 0 ? <div className={classes.badge}>
          <span>{countOrder}</span>
        </div>:""
        }
      
      </ListItem>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  item: {
    width: 'unset',
    height: 40,
    marginRight: 8,
    backgroundColor: '#fff !important',
    '&:hover': {
      '&:after': {
        content: "''",
        position: 'absolute',
        bottom: 0,
        width: 24,
        height: 2,
        backgroundColor: color.link,
      },
    },

    '&.Mui-selected': {
      '&:after': {
        content: "''",
        position: 'absolute',
        bottom: 0,
        width: 24,
        height: 2,
        backgroundColor: color.link,
      },
    },

    '& .MuiListItemText-root': {
      whiteSpace: 'nowrap',
    },
  },
  badge: {
    position: 'absolute',
    top: -3,
    right:10,
    backgroundColor: '#DE350B',
    width: 18,
    height: 18,
    borderRadius: 9,
    justifyContent: 'center',
    alignItems: 'center',
    display:'flex',
    color:'white'
  },
}));

export default Menu;
