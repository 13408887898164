import React, { useEffect ,useState} from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppMenu } from 'containers';
import { NetworkBar, SearchBar, ColorButton } from 'components';
import { Paper, Hidden, IconButton, Drawer, Button } from '@material-ui/core';
import { List, ListItem } from '@material-ui/core';
import { Dropdown } from 'antd';
import { profileAction } from 'actions/profile';
import { privateRoute } from 'routes';
import { UserAvatar } from 'views/Profile/components';
import { lbprofileAction } from "actions/lootbox/profile";
import { useTranslation } from 'react-i18next';
import { StarFilled } from '@ant-design/icons';
import {CopyToClipboard} from 'react-copy-to-clipboard';


import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import LanguageBar from 'components/LanguageBar'

const Header = () => {
  const { t, i18n } = useTranslation('common');

  const { isLoggedIn, mintable = false } = useSelector(({ profileLootbox }) => profileLootbox);

  const [isOpenMenu, setIsOpenMenu] = React.useState(false);
  const handleOpenMenu = (open) => (event) => setIsOpenMenu(open);
  const [userJwt, setUserJwt] = useState(null);
  const [addressSub, setAddress] = useState(null);

  const handleClickConnect = () => {
    lbprofileAction.connect();
  };
  const handleValue=()=>{
    if(userJwt){
      const str = userJwt.wallet_address
      return userJwt.wallet_address.substr(0,3) + userJwt.wallet_address.substr(str.length - 3, str.length-1)
    }
  }

  useEffect(()=>{
    const jwtJson = localStorage.getItem("jwtJson");
    if(jwtJson != null && jwtJson != undefined){
      const json = JSON.parse(jwtJson)
      setUserJwt(json)
      const str = json.wallet_address
      const add =str.substr(0,3) +"..."+ str.substr(str.length - 3, str.length-1)
      setAddress(add);
    }
  },[isLoggedIn])

  const handleClickDisconnect = () => {
    lbprofileAction.signOut();
  };

  return (
    <Paper square className='App-Header' color='primary'>
      <Hidden xsDown>
        <Link to={privateRoute.home.path} className='mr-20'>
          <img src='/assets/icons/logo-primary.svg' height={40} />
        </Link>
      </Hidden>
      <NetworkBar />

      <div style={{ flex: 2 }} />

      <Hidden mdUp>
        <IconButton onClick={handleOpenMenu(true)}>
          <MenuOutlinedIcon />
        </IconButton>

        <Drawer anchor='top' open={isOpenMenu} onClose={handleOpenMenu(false)}>
          <List style={{ width: '100%' }}>
            <AppMenu onClickMenu={handleOpenMenu(false)} />
          </List>
        </Drawer>
      </Hidden>
      <AppMenu onClickMenu={handleOpenMenu(false)} />
      {isLoggedIn ? (
        <>
         {userJwt && userJwt.level_id != 9 && <Button className='header-level' ><StarFilled />{' '} VIP {userJwt && userJwt.level_id }</Button>} 
          
          <Dropdown
            trigger='click'
            placement='bottomRight'
            getPopupContainer={(event) => event.parentNode}
            overlay={
              <div>
                <List disablePadding component={Paper} style={{ marginTop: 6, minWidth: 180 }}>
                  <CopyToClipboard text={userJwt && userJwt.wallet_address }>
                  <ListItem button component={Link}>
                    {t('header.copyaddress')}
                  </ListItem>
                  </CopyToClipboard>
                  <ListItem button divider component="a" href="/history">
                    {t('header.history')}
                  </ListItem>                  
                  <ListItem button onClick={handleClickDisconnect}>
                    {t('header.disconnect')}
                  </ListItem>
                </List>
              </div>
            }
          >
            <Button style={{ marginRight: '5px' }} variant='outlined' color='inherit' endIcon={<ArrowDropDownOutlinedIcon />}>
              {addressSub}
            </Button>
          </Dropdown>
          {mintable && (
            <Link to={privateRoute.createType.path}>
              <ColorButton startIcon={<CloudUploadOutlinedIcon />}>Create</ColorButton>
            </Link>
          )}
        </>
      ) : (
        <ColorButton onClick={handleClickConnect} style={{marginRight:"10px"}}>Connect wallet</ColorButton>
      )}
      <LanguageBar init />
    </Paper>
  );
};

export default Header;
