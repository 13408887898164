import React, { Fragment, useEffect, useState } from 'react';
import { Pagination } from '@material-ui/lab';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import './history.scss';
import Collapse from '@material-ui/core/Collapse';
import Fade from '@material-ui/core/Fade';

import { HISTORY_STATUS, STATUS_CLASSNAME } from './constants';
import { MOCK_DATA } from './mock-data';
import { commonService } from 'services/lootbox/commonService';
import common from 'reducers/loobox/common';
import { Table, Tag, Space, Card } from 'antd';

export default function History() {
  const [histories, setHistories] = useState([]);
  const [page, setPage] = useState(1);
  const [checked, setChecked] = React.useState(true);
  const [hidden, setHidden] = useState(false);
  const [his, setHis] = useState([]);
  const [expanRowKey,setExpan] = useState([])
  
  const handlePageChange = (event, newPageIndex) => {
    setHidden(true);
    setTimeout(() => {
      setHidden(false);
      setPage(newPageIndex);
      setChecked((prev) => !prev);
    }, 300);
    
  };
  const handleValue=(data)=>{
    return data.substr(0,6) +"..."+ data.substr(data.length - 6, data.length-1)
  }

  const elHeight = document.getElementsByClassName('history--table--item--container').clientHeight;

  useEffect(async() => {
    const history = await commonService.historyList();
    const temp = [];
    history.data.map(m =>{
      temp.push({...m, key: m.id})
    })
    temp.sort((a,b)=>{
      return new Date(b.CreatedAt) - new Date(a.CreatedAt);
    }) 
    setHistories(temp)
    setPage(1)
  }, []);

  const _renderHistoryItems = (historyItems, page) =>
    historyItems.slice((page - 1) * 4, page * 4).map((item) => {
      return (
        <Grid
          container
          key={item.id}
          className={`history--table--item--container ${hidden ? 'hidden' : ''}`}
          justifyContent='center'
          alignItems='center'
        >
          <Grid item xs={3} className='text-left history--table--text' style={{ 'word-break': 'break-all', 'padding' : '0px 5px' }}>
            {handleValue(item.transaction_hash)}
          </Grid>          
          <Grid item xs={3} className='text-center history--table--date'>
            {moment(item.CreatedAt).format('HH:mm DD/MM/yyyy')}
          </Grid>          
          {/* <Grid item xs={2} className='text-left history--table--text' style={{ 'word-break': 'break-all', 'padding' : '0px 5px' }}>
            {item.id}
          </Grid> */}
          {/* <Grid item xs={1} className='text-center history--table--text'>
            {item.total_amount}
          </Grid> */}
          {/* <Grid item xs={1} className='text-right history--table--price'>
              {item.total_amount}
          </Grid>           */}
          <Grid item xs={3} className='text-center history--table--price'>
              {item.total_amount} {item.currency}
          </Grid>
          <Grid item xs={3} className='text-center d-flex justify-content-center'>
            <div className={`history--status ${STATUS_CLASSNAME[item.status]}`}>
              <span className='history--status--text d-flex flex-column justify-content-center'>
                {item.status && item.status === 3 ? "Approved" : item.status ===2?"pending":"Rejected"}
              </span>
            </div>
          </Grid>
        </Grid>
      );
    });

    const columns = [
      {
        title: 'Transaction Id',
        dataIndex: 'transaction_hash',
        key: 'transaction_hash',
        render(h) {
          if(h == "" || !h){
            return ""
          }
        return  handleValue(h)
        },
      },
      {
        title: 'Date',
        dataIndex: 'CreatedAt',
        key: 'CreatedAt',
        render(h) {
         return moment.utc(h).format('HH:mm DD/MM/yyyy')
        }
      },
      {
        title: 'Value',
        dataIndex: 'total_amount',
        key: 'total_amount',
        render(h,record) {
         return h + " " + record.currency
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'Status',
        render(h) {
        return  <div className={`history--status ${STATUS_CLASSNAME[h]}`}>
              <span className='history--status--text d-flex flex-column justify-content-center'>
                {h && h === 3 ? "Approved" : h ===2?"pending":"Rejected"}
              </span>
            </div>
        },
      },
      
    ];

    const columns2 = [
      {
        title: 'Name',
        dataIndex: 'product_name',
        key: 'product_name'
      },
      {
        title: 'Amount',
        dataIndex: 'quantity',
        key: 'quantity'
      },
      {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        render(h) {
         return h + " USDT/USDC"
        },
      },
      {
        title: 'Total value',
        dataIndex: 'total_amount',
        key: 'total_amount',
        render(h,record) {
         return record.price * record.quantity + " "+ record.currency
        },
      },
      
    ];

    const handleOnExpan= async(a,record)=>{
      const res =await commonService.history(record.id)
      let key= [];
      if(expanRowKey.includes(record.id)){
        key.push([]);
      }else{
        key.push(record.id);
      }
      
      setExpan(key)
      setHis(res.data.orderdetails)
    }
    const expandedRow = row => {
     
      return <Table columns={columns2} dataSource={his}  pagination={false} />;
    };

  return (
    // <Grid className='history--container mx-auto'>
    //   <span className='history--title'>Orders History</span>
    //   <Grid container className='history--table--header'>
    //     <Grid item xs={3} className='text-left'>
    //       <span>Transaction Id</span>
    //     </Grid>
    //     <Grid item xs={3} className='text-center'>
    //       <span>Date</span>
    //     </Grid>                
    //     {/* <Grid item xs={2} className='text-left'>
    //       <span>Item</span>
    //     </Grid> */}
    //     {/* <Grid item xs={2} className='text-center'>
    //       <span>Total amount</span>
    //     </Grid>
    //     <Grid item xs={2} className='text-center'>
    //       <span>Price<br /> (USDC/USDT)</span>
    //     </Grid>         */}
    //     <Grid item xs={3} className='text-center'>
    //       <span>Total Value</span>
    //     </Grid>
    //     <Grid item xs={3} className='text-center'>
    //       <span>Status</span>
    //     </Grid>
    //   </Grid>
    //   <Collapse in={checked} collapsedSize={elHeight * MOCK_DATA.slice((page - 1) * 8, page * 8).length}>
    //     {_renderHistoryItems(histories, page)}
    //     <Grid container justifyContent='center'>
    //       <Pagination count={Math.ceil(histories.length / 4)} shape='rounded' page={page} onChange={handlePageChange} />
    //     </Grid>
    //   </Collapse>
    // </Grid>
    <Fragment>
      
     <div className="container">
     <Card className="col-12" style={{marginTop:20}}>
     <Table columns={columns} expandable={{
      expandedRowRender: expandedRow,
      expandedRowKeys: expanRowKey,
      onExpand: (a,b) => handleOnExpan(a,b),
      expandRowByClick:true
    }} dataSource={histories} />
     </Card>
     </div>
    </Fragment>
  );
}
