export const ActionType = {
    COUNT_ORDER : "COUNT_ORDER",
    TRANS_HASH : "TRANS_HASH"
  };

  const initState ={
    count:  0,
    trans_hash: ""
  }

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  
  
  const common = (state  = {...initState}, { type, data }) => {
    switch (type) {
      case ActionType.COUNT_ORDER:
        const a = getRandomInt(1000)
        return {...state, count : a};
        case ActionType.TRANS_HASH:
          return {...state, trans_hash : data};
        default:
            return state;
    }
    
  };
  
  export default common;
  