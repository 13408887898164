export const ActionType = {
    GET_LOOTBOX_LIST: 'GET_LOOTBOX_LIST',
    GET_LOOTBOX_DETAIL: 'GET_LOOTBOX_DETAIL'
  };
  
  const lootbox = (state = {}, { type, data }) => {
    switch (type) {
      case ActionType.GET_LOOTBOX_LIST:
        return {data};
      case ActionType.GET_LOOTBOX_DETAIL:
        const profile = { data };
        return profile;
      default:
        return state;
    }
  };
  
  export default lootbox;
  