export const API_URI = process.env.REACT_APP_API_URI || process.env.NEXT_PUBLIC_API_URI;
export const API_URI_LOOTBOX = process.env.REACT_APP_LOOTBOX_API || process.env.REACT_APP_LOOTBOX_API;
export const MAINNET = process.env.REACT_APP_ENV_RUNTIME === "false" ? false : true
export const URL_LAND =process.env.REACT_APP_MAINNET || "http://netvr.xyz:8002";
//export const USDC_CONTRACT = process.env.REACT_APP_USDC || "0xd1Aad5b8a5a69731A1876f7B1cc99a39C902AbCA"
//export const USDT_CONTRACT = process.env.REACT_APP_USDT || "0x25a77eaec3089A3205bd82BC09CA1C1a0e08E8E7"
export const USDC_CONTRACT = process.env.REACT_APP_USDC || "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48"
export const USDT_CONTRACT = process.env.REACT_APP_USDT || "0xdac17f958d2ee523a2206206994597c13d831ec7"
export const NETVRK_CONTRACT = process.env.REACT_APP_NETVRK || "0xc531025e604cbc8f1077d8a073bc0c89d28cb1eb"
export const ADDRESS_RECEIVER = process.env.REACT_APP_ADDRESS_RECEIVER || "0x8137a627703d2064ecdc3dbef3bf0380735a3473"
export const outputCurrency = process.env.REACT_APP_OUPUT_CURRENCY || ""
export const GAS = process.env.REACT_APP_GAS || 285278
