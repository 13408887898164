import { createStore, combineReducers } from 'redux';
import profile from './profile';
import system from './system';
import coreui from './coreui';
import profileLootbox from './loobox/profile';
import campaign from './loobox/campaign';
import lootbox from './loobox/lootbox';
import checkout from './loobox/checkout';
import common from  './loobox/common';
export const store = createStore(
  combineReducers({
    profile,
    system,
    coreui,
    profileLootbox,
    campaign,
    lootbox,
    checkout,
    common
  }),
  {},
);
